//@flow
import React from 'react';
// import Reaptcha from 'reaptcha';
import { injectIntl, FormattedMessage } from 'react-intl';
import { navigate } from 'gatsby-plugin-intl';

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Checkbox from '../components/Checkbox';

import { Title } from '../components/typo';
import auth from '../services/auth';

import MailIcon from '../icons/Mail.svg';

import {
  Wrapper,
  ConfirmationWrapper,
} from './Login.style';

type Props = {
  register: Boolean,
  intl: any,
  location: any,
};

class Login extends React.PureComponent<Props> {
  state = {
    email: '',
    tos: false,
    loading: false,
    sent: false,
    errorCode: false,
  };

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleCheckboxUpdate = event => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleSubmit = async event => {
    const { intl } = this.props;
    const { sent, email, tos } = this.state;

    console.log('**** handleSubmit', sent, email, tos);

    // // Code validation form
    // if (sent && isRegistered && (!code)) {
    //   return;
    // }

    // // Code validation with register fields
    // if (sent && !isRegistered && (!code || !tos || !team)) {
    //   return;
    // }

    event.preventDefault();

    this.setState({
      loading: true,
    });

    try {
      await auth.fastlogin(email, intl.locale);
      navigate('/app/', {
        replace: true
      });
    } catch (e) {
      console.log('****', e.message);
      const errorState = {};

      if (e.message === 'BAD_CODE') {
        errorState.errorCode = true;
      }

      this.setState({
        loading: false,
        ...errorState,
      });
    }
    this.setState({
      loading: false
    });

    // if (!sent) {
    //   console.log('**** execute captcha');
    //   // await this.captcha.reset();
    //   // await this.captcha.execute();
    //   // const captchaResp = await this.waitCaptcha();
    //   this.sendMagicLink();
    //   return false;
    // }

    // try {
    //   if (!isRegistered) {
    //     const resp = await auth.register(email, team, code);
    //     console.log('registered', resp);
    //     navigate('/app/', {
    //       replace: true
    //     });
    //     return;

    //   } if (isRegistered) {
    //     await auth.login(email, code);
    //     console.log('go auth', email, code);

    //     navigate('/app/', {
    //       replace: true
    //     });
    //     return;
    //   }
    // } catch (e) {
      // console.log('****', e.message);
      // const errorState = {};

      // if (e.message === 'BAD_CODE') {
      //   errorState.errorCode = true;
      // }

      // this.setState({
      //   loading: false,
      //   ...errorState,
      // });
    // }
  };


  renderSubmitButton = () => {
    const { register } = this.props;
    const { email, tos, loading, sent } = this.state;
    let text;

    if (loading) {
      text = <Loader type="Oval" color="#FFF" height={26} />;
    } else {
      text = <FormattedMessage id="login.action" />;
    }

    let disabled = loading || !tos || !email;

    return (
      <button
        className={`button ${loading ? 'loader' : ''}`}
        type="submit"
        disabled={disabled}
      >
        {text}
      </button>
    );
  };

  renderError = () => {
    const { errorCode } = this.state;

    if (!errorCode) {
      return null;
    }

    console.log('****', errorCode);

    let message = 'Erreur inconnue';
    switch (errorCode) {
      case 'BAD_CODE':
        message = 'Le code est incorrect';
        break;
      case 'UNKNOWN_DOMAIN':
        message = 'Cette adresse email n\'est pas autorisée. Veuillez utiliser l\'adresse email de votre société';
        break;
      case 'USER_DEACTIVATED':
      case 'CLIENT_DEACTIVATED':
        message = 'Ce compte a été désactivé';
        break;
      case 'BAD_EMAIL':
        message = 'Veuillez entrer un email valide';
        break;
      case 'BAD_SUBDOMAIN':
        message = 'Cette application n\'est pas autorisée';
        break;
      default:
        break;
    }
    return (
      <div className="error-message">
        {message}
      </div>
    );
  };

  renderForm = () => {
    const { register } = this.props;
    const { email, tos, sent } = this.state;

    const titleID = register || this.fromMagicLink ? 'register.title' : 'login.title';

    return (
      <>
        {!sent ? (
          <>
            <Title
              center
              t={titleID}
            />
            <label>
              <FormattedMessage id="login.email.label" />

              <div className="input-wrapper">
                <input
                  type="email"
                  name="email"
                  placeholder="my@email.com"
                  defaultValue={email}
                  onChange={this.handleUpdate}
                />
              </div>
            </label>
            <label className="tos">
              <Checkbox
                name="tos"
                checked={tos}
                onChange={this.handleCheckboxUpdate}
              />
              <span><FormattedMessage id="register.legal" /></span>
            </label>
          </>
        ) : null}
        {this.renderError()}
        {this.renderSubmitButton()}
      </>
    );
  };

  render() {
    return (
      <Wrapper>
        <form method="post" onSubmit={this.handleSubmit}>
          {this.renderForm()}
        </form>
      </Wrapper>
    );
  }
}

Login.defaultProps = {
  register: false,
};

export default injectIntl(Login);