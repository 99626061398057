import styled from 'styled-components';

export const Wrapper = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;

  &.center {
    text-align: center;
  }
`;
