// @flow

import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from 'gatsby-plugin-intl';

type Props = {
  children: React.Node,
  t: String,
  d?: String,
  center?: Boolean,
};

import { Wrapper } from './Title.style';

const Title = ({ children, t, d, center }: Props) => (
  <Wrapper className={classNames({ center })}>
    {children ? children : <FormattedMessage id={t} defaultMessage={d} />}
  </Wrapper>
);

Title.defaultProps = {
  center: false,
};

export default Title;
