import styled from 'styled-components';
import dropDownIcon from '../images/down-arrow.png';

export const Wrapper = styled.main`
  margin: 40px 0;
  min-height: 50vh;

  label {
    display: block;
    text-transform: uppercase;
    font-family: Montserrat;
    color: #FFF;
    margin-bottom: 30px;
  }

  input {
    ::placeholder {
      color: #FFF;
      opacity: 0.6;
    }
  }

  input, select {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 15px 0;
    border: 1px solid #FFF;
    background: transparent;
    min-height: 40px;
    padding: 0 15px;
    color: #FFF;
    font-size: 16px;
  }
  
  input.error {
    border-color: red;
  }

  .error-message {
    text-transform: none;
    color: red;
  }

  select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: none;

    background: url(${dropDownIcon}) no-repeat;
    background-position: right 15px center, right 10px center;
    background-size: 12px;
  }

  input[disabled], button[disabled] {
    transition: opacity 300ms ease-in-out;
    opacity: 0.7;
    user-select: none;
  }

  .input-wrapper {
    position: relative;

    .icon {
      position: absolute;
      top: 9px;
      right: 10px;
    }
  }

  .tos {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      text-transform: none;
      font-size: 13px;
      line-height: 1.3em;
    }
  }
`;

export const ConfirmationWrapper = styled.div`
  text-align: center;
  margin-bottom: 30px;

  h2 {
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    margin-top: 0;
  }
`;