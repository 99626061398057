import styled from 'styled-components';

export const Wrapper = styled.h2`
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: lighter;

  &.center {
    text-align: center;
  }
`;
