//@flow

import React from 'react';

import SEO from '../components/seo';
import LoginScreen from '../screens/Login';

type Props = {};

class IndexPage extends React.PureComponent<Props> {
  render = () => {
    return (
      <>
        <SEO title="Login" />
        <LoginScreen />
      </>
    );
  };
}

export default IndexPage;
